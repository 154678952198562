import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { history } from './helper/history';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SVTLandingPage from './components/SVTLandingPage';
import { store, persistor } from './helper/store';
import { PersistGate } from 'redux-persist/integration/react';
import SVTOrderHistoryComponent from './components/aggregators/SVTOrderHistoryComponent';
import SVTOrderHelpComponent from './components/aggregators/SVTOrderHelpComponent';
import SVTOrderViewComponent from './components/aggregators/SVTOrderViewComponent';
import SVTOrderSettingPage from './components/aggregators/SVTOrderSettingPage';
import SVTOrderHistoryDetails from './components/aggregators/SVTOrderHistoryDetails';
import SVTNotification from './components/aggregators/SVTNotification';
import SVTOrderListScheduler from './components/aggregators/SVTOrderListScheduler';
import SVTMenuPage from './components/aggregators/SVTMenuPage';
import OfflinePopup from './components/aggregators/SVTOfflinePopup';
import { delayForGivenTime, handleResponse } from './helper/common';
import { withLDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { initializeFlags } from './helper/launchDarkly';
import { apiUrl } from './url/apiUrl';
import { constants } from './constant/reducerConstant';

const firebaseConfig = new URLSearchParams({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}).toString();

const LaunchDarklyConfig = {
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
};

const SVTAppWithLaunchDarkly = withLDProvider(LaunchDarklyConfig)(SVTApp);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`../firebase-messaging-sw.js?${firebaseConfig}`)
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

function SVTApp() {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const [showOfflinePopup, setShowOfflinePopup] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const checkInternetConnection = async () => {
    setIsLoading(true);
    if (navigator.onLine) {
      setIsOnline(true);
      setShowOfflinePopup(false);
    }
    await delayForGivenTime(300);
    setIsLoading(false);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    window.addEventListener('online', () => {
      setIsOnline(true);
      setShowOfflinePopup(false);
    });
    window.addEventListener('offline', () => {
      setIsOnline(false);
      setShowOfflinePopup(true);
    });
    return () => {
      window.removeEventListener('online', () => {
        setIsOnline(true);
        setShowOfflinePopup(false);
      });
      window.removeEventListener('offline', () => {
        setIsOnline(false);
        setShowOfflinePopup(true);
      });
    };
  }, []);

  const flags = useFlags();
  //save the flags in a launchDarkly helper component for use in other component / functions
  initializeFlags(flags);
  const enableHeartbeatCheck = flags['enableHeartbeatCheck'];
  const fiveSecHeartbeatCheck = flags['5SecHeartbeatCheck'];

  React.useEffect(() => {
    // Event listener for the custom event
    document.addEventListener('pollServer', handlePolling);

    // Start the polling by dispatching the event
    getStoreDetails().then((storeDetails) => {
      console.log(' ', storeDetails);

      // Define a custom event to handle the polling
      const pollEvent = new CustomEvent('pollServer', {
        detail: {
          enableHeartbeatCheck: enableHeartbeatCheck,
          fiveSecHeartbeatCheck: fiveSecHeartbeatCheck,
          siteId: storeDetails.store_data[0].site_id,
          dispatch: dispatch,
        },
      });

      document.dispatchEvent(pollEvent);
    });
  }, [dispatch, enableHeartbeatCheck, fiveSecHeartbeatCheck]);

  return (
    <BrowserRouter history={history}>
      <SVTOrderListScheduler isOnline={isOnline} />
      <SVTNotification isOnline={isOnline} />
      <OfflinePopup
        show={showOfflinePopup}
        retryHandler={checkInternetConnection}
        isLoading={isLoading}
      />
      <Switch>
        <Route exact path='/' component={SVTLandingPage} />
        <Route
          exact
          path='/order-details/:type'
          component={SVTOrderViewComponent}
        />
        <Route exact path='/order-list' component={SVTLandingPage} />
        <Route
          exact
          path='/order-history'
          component={SVTOrderHistoryComponent}
        />
        <Route exact path='/order-help' component={SVTOrderHelpComponent} />
        <Route exact path='/order-setting' component={SVTOrderSettingPage} />
        <Route
          exact
          path='/order-history-details'
          component={SVTOrderHistoryDetails}
        />
        <Route exact path='/menu' component={SVTMenuPage} />
      </Switch>
    </BrowserRouter>
  );
}

// Function to handle polling
function handlePolling(event) {
  const { enableHeartbeatCheck, fiveSecHeartbeatCheck, siteId, dispatch } =
    event.detail;
  if (enableHeartbeatCheck === undefined || !enableHeartbeatCheck) {
    return;
  }

  const pingInterval = fiveSecHeartbeatCheck ? 5000 : 60000;

  try {
    pingAmpServer(siteId, dispatch);
    pingOrderInServer();
  } catch (error) {
    console.error('An error occurred:', error);
  }

  setTimeout(() => {
    const pollEvent = new CustomEvent('pollServer', {
      detail: {
        enableHeartbeatCheck: enableHeartbeatCheck,
        fiveSecHeartbeatCheck: fiveSecHeartbeatCheck,
        siteId: siteId,
        dispatch: dispatch,
      },
    });
    document.dispatchEvent(pollEvent);
  }, pingInterval);
}

// Function to execute the graphql mutation
function pingAmpServer(siteId, dispatch) {
  console.log('authInfo', store.getState().authInfo);
  let idToken = store.getState().authInfo?.accessToken;

  if (isJwtExpired(idToken)) {
    console.log('Access token expired');
    const refreshToken = store.getState().authInfo?.sessionData?.refresh_token;

    refreshAccessToken(refreshToken).then((data) => {
      console.log('refreshed token...', data);
      idToken = data.id_token;
      console.log('refreshed ID token...', idToken);

      dispatch(storeAccessToken(idToken));
    });
  }

  const endpoint = process.env.REACT_APP_AMP_API_URL;
  const token = store.getState().authInfo?.accessToken;
  console.log('time', new Date().toISOString());
  const input = {
    orderInLastPingTime: new Date().toISOString(),
  };

  const mutation = `
            mutation UpdatePingStatus($siteId: String!) {
              updatePingStatus(internalSiteID: $siteId){
                internalSiteID
                id
                orderInLastPingTime
              }
            }
        `;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = fetch(endpoint, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        query: mutation,
        variables: { siteId, input },
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = response.json();
    console.log(data);
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }
}

// write a function to test if a JWT token is expired
function isJwtExpired(token) {
  try {
    // Decode the token without verifying its signature
    const payload = JSON.parse(atob(token.split('.')[1]));

    // Check if the "exp" claim is present
    if (!payload.exp) {
      return false; // Token doesn't have an expiration claim
    }

    // Get the current time in seconds since the epoch
    const currentTime = Math.floor(Date.now() / 1000);

    // Compare the current time with the expiration time
    if (currentTime > payload.exp) {
      return true; // Token is expired
    } else {
      return false; // Token is not expired
    }
  } catch (e) {
    console.error('Error decoding JWT', e);
    return false; // Return false if there's an error decoding the token
  }
}

function refreshAccessToken(refreshToken) {
  var urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'refresh_token');
  urlencoded.append('client_id', apiUrl.clientId);
  urlencoded.append('refresh_token', refreshToken);

  // Convert client_id and client_secret to a Basic Authentication header
  const basicAuth =
    'Basic ' + btoa(apiUrl.clientId + ':' + apiUrl.clientSecret);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: basicAuth,
    },
    body: urlencoded,
  };

  return fetch(apiUrl.authUrl, requestOptions)
    .then(handleResponse)
    .then((data) => {
      console.log('refreshed token', data);
      return data;
    });
}

// Function to execute the ping test
function pingOrderInServer() {
  const requestOptions = {
    method: 'GET',
    headers: {
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${process.env.REACT_APP_URL}/ping-test`;

  try {
    const response = fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = response.json();
    console.log('Ping test response:', data);
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

function getStoreDetails() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/my_store_details`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function storeAccessToken(token) {
  return { type: constants.SAVE_ACCESS_TOKEN, token };
}

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SVTAppWithLaunchDarkly />
      </PersistGate>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
