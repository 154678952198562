/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, withRouter } from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';
import bp_logo from '../../images/bp_logo.svg';
import bp_text from '../../images/bp_text.svg';
import online_indicator from '../../images/online_indicator.svg';
import settings_button from '../../images/settings_button.svg';
import notification_button from '../../images/notification_button.svg';
import SVTNotificationComponent from './SVTNotificationComponent';
import offline_indicator from '../../images/offline_indicator.svg';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { compose } from 'redux';
import { connect } from 'react-redux';
import risk_icon from '../../images/Risk.svg';
import { orderManagementServices } from '../../services/SVTOrderManagementService';
import close_icon from '../../images/green_outline_close.svg';
import info_icon from '../../images/info.svg';
import bpIconTickCircleBlack from '../../images/bp-icon-tick-circle-black.svg';
import aggregator_offline_indicator from '../../images/aggregator_offline_indicator.svg';
import complete_check from '../../images/complete_check.png';
import moveToHistory from '../../images/move-to-history.svg';
import OfflinePopupIcon from '../../images/bp-icon-globe-web-bp-orange.svg';
import StoreOfflineTime from '../../services/StoreOfflineTimer';

const activeMainHeaderLinkLiveOrder = 'active-main-header-link-live-order';

const deactiveAllOfflineNotifications = async (props) => {
  const offlineMessageNotifications = props.allRawNotificationsList.filter(
    (notification) =>
      notification['message'].startsWith('Store Marked Offline At')
  );

  const requests = offlineMessageNotifications.map((notification) => {
    return props.updateNotificationStatus({
      id: notification.id,
      status: 'Deactive',
    });
  });

  Promise.all(requests).then(() => {
    props.getAllNotificationsList();
  });
};

const handleStoreStatusChange = (
  props,
  storeStatus,
  isBarNotificationActive,
  barNotificationType,
  setShowTopMsg
) => {
  if (storeStatus.toLowerCase() === 'offline') {
    if (isBarNotificationActive && barNotificationType !== 'store') {
      return;
    }
    const headerMsgPayload = {
      show: true,
      type: 'store',
      msg: 'All incoming orders to the store are manually paused.',
      orderId: '',
      partnerId: '',
    };
    setShowTopMsg(headerMsgPayload);
  } else if (storeStatus.toLowerCase() === 'online') {
    if (
      isBarNotificationActive &&
      barNotificationType !== 'store_offline' &&
      barNotificationType !== 'store_unattended'
    ) {
      deactiveAllOfflineNotifications(props);
    }

    if (
      isBarNotificationActive &&
      barNotificationType !== 'store' &&
      barNotificationType !== 'store_offline' &&
      barNotificationType !== 'store_unattended'
    ) {
      return;
    }
    const headerMsgPayload = {
      show: false,
      type: '',
      msg: '',
      orderId: '',
      partnerId: '',
    };
    setShowTopMsg(headerMsgPayload);
  }
};

const getActiveNavLink = (location) => {
  const allNavLinks = {
    liveOrder: false,
    orderHistory: false,
    help: false,
  };
  let activeNavLink = {
    liveOrder: false,
    orderHistory: false,
    help: false,
    menu: false,
  };
  switch (location.pathname) {
    case '/order-details/Incoming':
      activeNavLink = { ...allNavLinks, liveOrder: true };
      break;
    case '/order-list':
      activeNavLink = { ...allNavLinks, liveOrder: true };
      break;
    case '/order-history-details':
      activeNavLink = { ...allNavLinks, orderHistory: true };
      break;
    case '/help':
      activeNavLink = { ...allNavLinks, help: true };
      break;
    case '/menu':
      activeNavLink = { ...allNavLinks, menu: true };
      break;
    default:
      console.log('Proper name for nav links should pass to main header');
      break;
  }

  return activeNavLink;
};

const getClassNameSelected = (props) => {
  let classNameSelected = 'setting-store-notification';
  if (props?.showTopMsg?.type !== 'store') {
    if (
      props?.showTopMsg?.type !== 'Cancelled' &&
      props?.showTopMsg?.type !== 'saleIngestion' &&
      props?.showTopMsg?.type !== 'PendingAction' &&
      props?.showTopMsg?.type !== 'failure' &&
      props?.showTopMsg?.type !== 'store_offline' &&
      props?.showTopMsg?.type !== 'store_unattended'
    ) {
      classNameSelected = 'accept-store-notification-header';
    } else {
      classNameSelected = 'cancel-store-notification-header';
    }
  } else if (
    props?.showTopMsg?.type === 'saleIngestion' ||
    props?.showTopMsg?.type === 'PendingAction'
  ) {
    classNameSelected = 'sale-ingestion-fail-store-notification';
  }

  return classNameSelected;
};

const HeaderLeftNavlinks = (props) => {
  const { activeNavLink, totalUnavailableCount } = props;

  return (
    <Nav className='me-auto'>
      <NavLink
        exact
        activeClassName='active_class'
        className={
          'header_text ' +
          (activeNavLink.liveOrder ? activeMainHeaderLinkLiveOrder : '')
        }
        to='/'
      >
        Live Order
      </NavLink>
      <NavLink
        exact
        activeClassName='active_class'
        className={
          'header_text ' +
          (activeNavLink.orderHistory ? activeMainHeaderLinkLiveOrder : '')
        }
        to='/order-history'
      >
        Order History
      </NavLink>
      <div className='d-flex'>
        <NavLink
          exact
          activeClassName='active_class'
          className={
            'header_text ' +
            (activeNavLink.menu ? activeMainHeaderLinkLiveOrder : '')
          }
          to='/menu'
        >
          Menu
        </NavLink>
        {totalUnavailableCount > 0 && (
          <div className='menu_count_header ms-2 gray_background_color'>
            {totalUnavailableCount}
          </div>
        )}
      </div>
      <NavLink
        exact
        activeClassName='active_class'
        className={
          'header_text ' +
          (activeNavLink.help ? activeMainHeaderLinkLiveOrder : '')
        }
        to='/order-help'
      >
        Help
      </NavLink>
    </Nav>
  );
};

function SVTHeaderComponent(props) {
  const {
    storeDetails: { store_status: storeStatus = 'Online' } = {},
    showTopMsg: {
      type: barNotificationType = '',
      show: isBarNotificationActive,
    } = {},
  } = props;

  useEffect(() => {
    props.getOrderReportCount();
    props.getStoreDetails();
  }, []);
  useEffect(() => {
    handleStoreStatusChange(
      props,
      storeStatus,
      isBarNotificationActive,
      barNotificationType,
      props.setShowTopMsg
    );
  }, [storeStatus]);

  const [showNotification, setShowNotification] = useState(false);
  const handleCloseNotification = () => setShowNotification(false);
  const handleShowNotification = () => {
    setShowNotification(true);
  };

  const storeOfflineTimePlaceHolder = StoreOfflineTime(
    props.storeDetails?.updated_on
  );
  const location = useLocation();
  const activeNavLink = getActiveNavLink(location);
  const classNameSelected = getClassNameSelected(props);

  return (
    <>
      <Navbar className='py-0' expand='md' sticky='top'>
        <Container
          fluid
          className='header-shadow padding-header white_background'
        >
          <Link to='/' className='ms-2 me-3 py-1'>
            <img src={bp_logo} alt='BP Logo' />
            <img className='ms-1' src={bp_text} alt='BP Text' />
          </Link>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            {HeaderLeftNavlinks({
              activeNavLink,
              totalUnavailableCount: props?.totalUnavailableCount,
            })}

            <Nav>
              <Nav.Link className='my-auto'>
                <div className='d-flex'>
                  <div className=''>
                    <img
                      src={
                        (props.storeDetails?.store_status === 'Offline' &&
                          offline_indicator) ||
                        (props.storeDetails?.store_status ===
                          'Aggregator Offline' &&
                          aggregator_offline_indicator) ||
                        online_indicator
                      }
                      alt='online'
                      className='pt-1'
                    />
                    <img
                      src={OfflinePopupIcon}
                      alt='offline'
                      width='0px'
                      height='0px'
                    />
                  </div>
                  <div className='ms-1 header_text' data-test='storeStatusText'>
                    {(props.storeDetails?.store_status === 'Offline' &&
                      'Offline') ||
                      (props.storeDetails?.store_status ===
                        'Aggregator Offline' &&
                        'Aggregator(s) Offline') ||
                      'Online'}
                  </div>

                  {(props.storeDetails?.store_status === 'Offline' &&
                    storeOfflineTimePlaceHolder) ||
                    ''}
                </div>
              </Nav.Link>

              <NavLink to='/order-setting'>
                <img
                  src={settings_button}
                  alt='settings'
                  className='header-svg-border-radius'
                />
              </NavLink>
              <Nav.Link className='position-relative'>
                <img
                  src={notification_button}
                  className='header-svg-border-radius'
                  alt='notification'
                  onClick={handleShowNotification}
                />
                {props.totalNotificationCount > 0 ? (
                  <div className='notification_style notification_position green_background-color'>
                    {props.totalNotificationCount}
                  </div>
                ) : (
                  ''
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <SVTNotificationComponent
            show={showNotification}
            onHide={handleCloseNotification}
          />
        </Container>
      </Navbar>
      {props?.showTopMsg?.show === true && (
        <div className='overlay-top-popup'>
          <div className='overlay-box-top-popup'>
            <div className={classNameSelected}>
              <OfflineStoreNotification {...props} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function mapState(state) {
  const { orderManagementRelated, menuManagementRelated } = state;
  const {
    showTopMsg,
    storeDetails,
    allRawNotificationsList,
    totalNotificationCount,
    currentPath,
  } = orderManagementRelated;
  const { totalUnavailableCount } = menuManagementRelated;
  return {
    showTopMsg,
    storeDetails,
    allRawNotificationsList,
    totalNotificationCount,
    currentPath,
    totalUnavailableCount,
  };
}

const actionCreators = {
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  getStoreDetails: orderManagementActions.getStoreDetails,
  storeOrderDetails: orderManagementActions.storeOrderDetails,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  getAllNotificationsList: orderManagementActions.getAllNotificationsList,
  setShouldShowEditOrderViewInOrderDetailsPage:
    orderManagementActions.setShouldShowEditOrderViewInOrderDetailsPage,
  setIsFromSaleInjectionTapToView:
    orderManagementActions.setIsFromSaleInjectionTapToView,
  updateStoreStatus: orderManagementActions.updateStoreStatus,
  updateNotificationStatus: orderManagementActions.updateNotificationStatus,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTHeaderComponent);

const NotificationIcon = (props) => {
  const { type, isProductAvailable } = props;

  let imgSrc = null;
  if (type !== 'menu') {
    if (
      type.toLowerCase() === 'cancelled' ||
      type.toLowerCase() === 'pendingaction' ||
      type === 'saleIngestion'
    ) {
      imgSrc = risk_icon;
    } else if (
      type === 'store' ||
      type === 'failure' ||
      type === 'store_offline' ||
      type === 'store_unattended'
    ) {
      imgSrc = risk_icon;
    } else if (type.toLowerCase().includes('completed')) {
      imgSrc = complete_check;
    } else if (type.toLowerCase() === 'move to order history') {
      imgSrc = moveToHistory;
    } else {
      imgSrc = info_icon;
    }
  } else {
    if (isProductAvailable) {
      imgSrc = bpIconTickCircleBlack;
    } else {
      imgSrc = info_icon;
    }
  }

  return <img src={imgSrc} alt='info icon' />;
};

const handleViewOrder = async (
  props,
  type,
  orderId,
  closeNotification,
  resumeOrder
) => {
  if (type !== 'store' && type !== 'saleIngestion') {
    if (
      [
        'assigningdriver',
        'driverarrived',
        'Incoming',
        'PendingAction',
        'Cancelled',
        'Move To Order History',
        'Completed',
      ].includes(type) &&
      orderId !== ''
    ) {
      const OrderDetails = await orderManagementServices.getSingleItemDetails(
        `id=${orderId}`
      );
      props.storeOrderDetails(OrderDetails['data'][0]);
      props.setShouldShowEditOrderViewInOrderDetailsPage(false);
    }
    if (['store_offline', 'store_unattended'].includes(type)) {
      resumeOrder();
    }
    closeNotification();
    if (orderId === '') {
      return;
    }
    if (
      [
        'Incoming',
        'PendingAction',
        'assigningdriver',
        'driverarrived',
      ].includes(type)
    ) {
      props.history.push('/order-details/Incoming');
    } else if (type === 'Cancelled') {
      props.history.push('/order-details/Cancelled');
    }
  } else if (type === 'saleIngestion') {
    const headerMsgPayload = {
      show: false,
      type: '',
      msg: '',
      orderId: '',
      partnerId: '',
    };
    props.setShowTopMsg(headerMsgPayload);
    props.setIsFromSaleInjectionTapToView(true);
    props.history.push('/order-history');
  } else {
    props.history.push('/order-setting');
  }
};

const handleResumeOrder = async (props) => {
  await deactiveAllOfflineNotifications(props);
  props?.updateStoreStatus('Online', '', props?.storeDetails?.store_id);
  props.setShowTopMsg({
    show: false,
    type: '',
    msg: '',
    orderId: '',
  });
};

const handleCloseNotification = (props, storeStatus) => {
  let headerMsgPayload;
  if (storeStatus.toLowerCase() === 'offline') {
    headerMsgPayload = {
      show: true,
      type: 'store',
      msg: 'All incoming orders to the store are manually paused.',
      orderId: '',
    };
  } else {
    headerMsgPayload = {
      show: false,
      type: '',
      msg: ``,
      orderId: '',
      partnerId: '',
    };
  }
  props?.setShowTopMsg(headerMsgPayload);
};

const NotificationMessage = (props) => {
  const {
    type,
    partnerId,
    orderId,
    isProductAvailable,
    msg,
    isModifier,
    numberOfProductsHavingThisModifier,
    currentPath,
    resumeOrder,
    viewOrder,
  } = props;

  switch (type) {
    case 'Cancelled': {
      return (
        <span className='ps-2'>
          Order
          <strong> #{partnerId + ' '}</strong>
          has been cancelled.
        </span>
      );
    }
    case 'Completed': {
      return (
        <span className='ps-2'>
          Order
          <strong> #{partnerId + ' '}</strong>
          has been marked as <strong>completed.</strong>
        </span>
      );
    }
    case 'multipleCompleted': {
      return (
        <span className='ps-2'>
          Multiple orders have been marked as&nbsp;
          <strong>completed.</strong>
        </span>
      );
    }
    case 'Move To Order History': {
      return (
        <span className='ps-2'>
          Order
          <strong> #{partnerId + ' '}</strong>
          has been moved to Order History.
        </span>
      );
    }
    case 'Incoming': {
      return (
        <span className='ps-2'>
          Order has been received: <strong> {partnerId + ' '}</strong>
        </span>
      );
    }
    case 'PendingAction': {
      return (
        <span className='ps-2'>
          Kindly review/accept Order : <strong> {partnerId + ' '}</strong> in
          the Incoming Orders tab
        </span>
      );
    }
    case 'assigningdriver': {
      return (
        <span className='ps-2'>
          Assigning driver to order <strong>#{partnerId}</strong>.
        </span>
      );
    }
    case 'driverarrived': {
      return (
        <span className='ps-2'>
          A driver has <strong>arrived</strong> to pick up order{' '}
          <strong>#{partnerId}</strong>.
        </span>
      );
    }
    case 'partialAcceptedOrderAfterAccepted': {
      return (
        <span className='ps-2'>
          Your changes made to order <strong>#{partnerId}</strong> have been
          updated.
        </span>
      );
    }
    case 'store': {
      return (
        <span className='ps-2'>
          All incoming orders to the store are manually paused.
        </span>
      );
    }
    case 'menu': {
      if (isProductAvailable === true) {
        return (
          <span className='ps-2'>
            <strong>{msg}</strong> marked as
            <strong> available</strong>
            {isModifier && (
              <span>
                &nbsp;for <strong>{numberOfProductsHavingThisModifier}</strong>{' '}
                occurrences.
              </span>
            )}
          </span>
        );
      } else {
        return (
          <span className='ps-2'>
            <strong>{msg}</strong> marked as
            <strong> unavailable</strong>
            {isModifier && (
              <span>
                &nbsp;for <strong>{numberOfProductsHavingThisModifier}</strong>{' '}
                occurrences.
              </span>
            )}
          </span>
        );
      }
    }
    case 'saleIngestion': {
      return (
        <span className='ps-2'>
          <strong>
            {' '}
            Something went wrong with order <strong>#{partnerId}</strong> {','}{' '}
            please manually enter the details in the POS system.
          </strong>
        </span>
      );
    }
    case 'failure': {
      return (
        <span className='ps-2'>
          <strong>Error: </strong>
          {msg}
        </span>
      );
    }
  }

  if (
    type !== 'menu' &&
    type !== 'PendingAction' &&
    (orderId !== '' || type === 'store')
  ) {
    return (
      <div
        className={
          currentPath === '/order-setting' && type === 'store'
            ? 'd-none'
            : 'cursor-pointer green-link mx-2'
        }
        onClick={viewOrder}
      >
        Tap to view
      </div>
    );
  }

  if (type === 'store_online') {
    return <span className='ps-2'>{msg}</span>;
  }

  if (type === 'store_offline' || type === 'store_unattended') {
    return (
      <span className='ps-2' onClick={resumeOrder}>
        <strong>{msg}</strong>
      </span>
    );
  }

  return <></>;
};

export function OfflineStoreNotification(props) {
  const {
    currentPath,
    storeDetails: { store_status: storeStatus = 'Online' } = {},
  } = props;
  let {
    type = '',
    orderId = '',
    partnerId,
    isProductAvailable,
    msg = '',
    isModifier = false,
    numberOfProductsHavingThisModifier = 1,
  } = props.showTopMsg;

  if (partnerId === null || partnerId === undefined) {
    partnerId = '';
  }

  const viewOrder = async () => {
    handleViewOrder(props, type, orderId, closeNotification, resumeOrder);
  };

  const resumeOrder = async () => {
    handleResumeOrder(props);
  };

  function closeNotification() {
    handleCloseNotification(props, storeStatus);
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={10} className='px-4 cursor-pointer ' onClick={viewOrder}>
            <div
              className={
                type === 'offline' ? 'd-flex sale-ingestion-fail' : 'd-flex'
              }
            >
              <NotificationIcon
                type={type}
                isProductAvailable={isProductAvailable}
              />

              <NotificationMessage
                type={type}
                partnerId={partnerId}
                orderId={orderId}
                isProductAvailable={isProductAvailable}
                msg={msg}
                isModifier={isModifier}
                numberOfProductsHavingThisModifier={
                  numberOfProductsHavingThisModifier
                }
                currentPath={currentPath}
                resumeOrder={resumeOrder}
                viewOrder={viewOrder}
              />
            </div>
          </Col>
          <Col md={2}>
            {type !== 'store' && (
              <Row>
                <Col md={9}></Col>
                <Col md={3}>
                  <img
                    src={close_icon}
                    alt='close icon'
                    onClick={closeNotification}
                    className='cursor-pointer'
                  />
                </Col>
              </Row>
            )}
            {type === 'store' && (
              <Row>
                <Col md={5}> </Col>
                <Col md={7}></Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
