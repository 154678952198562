import { apiUrl } from '../url/apiUrl';

export const authServices = {
  callAuth,
  getUserData,
  refreshAccessToken,
};

function callAuth(code, _clientid) {
  var urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'authorization_code');
  urlencoded.append('code', code);
  urlencoded.append('client_id', apiUrl.clientId);
  urlencoded.append('redirect_uri', apiUrl.redirectUrl);
  urlencoded.append('client_secret', apiUrl.clientSecret);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: urlencoded,
  };
  return fetch(`${apiUrl.authUrl}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getUserData(data) {
  const payload = {
    idToken: data,
  };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: data },
    body: JSON.stringify(payload),
  };
  return fetch(`${apiUrl.url}/authorization-api`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data;
      }
    });
}

function refreshAccessToken(_refreshtoken) {
  var urlencoded = new URLSearchParams();
  urlencoded.append('client_id', apiUrl.clientId);
  urlencoded.append('token', _refreshtoken);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: urlencoded,
  };
  return fetch(`${apiUrl.revokeUrl}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        //auto logout if 401 response returned from api
      } else if (response.status === 400) {
        console.log('inside 400 else is...........', data);
      }
      const error = (data && data.message) || response.statusText;
      throw new Error(error);
    }
    return data;
  });
}
