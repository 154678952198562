import { constants } from '../constant/reducerConstant';

const initialState = {
  sessionData: {},
  redirectUrl: null,
  userData: {},
  accessToken: '',
  isAppInitializeAfterLogin: false,
};

export function authInfo(state = initialState, action = {}) {
  switch (action.type) {
    case constants.SAVE_AUTH_INFO:
      return {
        ...state,
        sessionData: action.data,
      };
    case constants.CLEAR_AUTH_INFO:
      return {
        ...state,
        sessionData: {},
      };
    case constants.SAVE_REDIRECT_PATH:
      return {
        ...state,
        redirectUrl: action.pathName,
      };
    case constants.CLEAR_REDIRECT_PATH:
      return {
        ...state,
        redirectUrl: null,
      };
    case constants.SAVE_USER_DATA:
      return {
        ...state,
        userData: action.data.body,
      };
    case constants.CLEAR_USER_DATA:
      return {
        ...state,
        userData: {},
      };
    case constants.CALL_REFRESH_TOKEN:
      return {
        ...state,
        callRefreshToken: action.val,
      };
    case constants.SAVE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.token,
      };
    case constants.SET_IS_APP_INITIALIZE_AFTER_LOGIN:
      return {
        ...state,
        isAppInitializeAfterLogin: action.data,
      };
    case constants.CLEAR_ALL_AUTH_INFO:
      return initialState;
    default:
      return state;
  }
}
