import { constants } from '../constant/reducerConstant';

const initialState = {
  orderList: [],
  selectedOrderDetails: {},
  orderHistoryList: [],
  selectedHistoryDetails: {},
  justCancelledOrderList: [],
  justCancelledSelectedOrderDetails: {},
  incomingOrders: [],
  deviceTokenDetails: undefined,
  orderHistoryFilter: [],
  orderByIdList: [],
  partnerList: [],
  incomingOrderNotifications: [],
  cancelledOrderNotifications: [],
  notificationList: [],
  updateOrderStatus: [],
  updateNotificationStatus: [],
  storeDetails: {},
  orderReportListCount: [],
  showPrintOption: false,
  updatedEditOrder: [],
  showEditedOrder: false,
  storePartiallyAcceptedItems: [],
  showEditOrder: false,
  showTopMsg: { show: false, type: '', msg: '', orderId: '' },
  userDetails: [],
  cancelIncomingOrder: [],
  totalNotificationCount: 0,
  recentNotificationsList: [],
  allNotificationsList: [],
  allRawNotificationsList: [],
  logoutApp: [],
  currentPath: '',
  profileDetails: [],
  backupIncomingOrder: [],
  storeDeviceToken: '',
  currentHeaderMsgOrderId: '',
  showMenuPopUp: false,
  menuSearchResults: [],
  showMenuPopUpModifier: false,
  trigerEditOrder: false,
  shouldShowSummary: true,
  completeOrder: [],
  cancelOrder: [],
  shouldShowEditOrderViewInOrderDetailsPage: false,
  isFromSaleInjectionTapToView: false,
};

const orderManagementActionFunctions = {
  [constants.GET_ORDER_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      orderList: action?.data?.data ? action.data.data : action.data,
    };
  },
  [constants.GET_ORDER_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      orderList: [],
    };
  },
  [constants.STORE_ORDER_DETAILS]: (state, action) => {
    return {
      ...state,
      selectedOrderDetails: action.order,
    };
  },
  [constants.GET_ORDER_HISTORY_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      orderHistoryList: action.data,
    };
  },
  [constants.GET_ORDER_HISTORY_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      orderHistoryList: [],
    };
  },
  [constants.STORE_ORDER_HISTOY_DETAILS]: (state, action) => {
    return {
      ...state,
      selectedHistoryDetails: action.order,
    };
  },
  [constants.GET_JUST_CANCELLED_ORDER_LIST_SUCCESS]: (state, action) => {
    const justCancelledOrderList = action.data?.data.filter(
      (order) => order.dismiss === 'false'
    );
    return {
      ...state,
      justCancelledOrderList: justCancelledOrderList,
    };
  },
  [constants.GET_JUST_CANCELLED_ORDER_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      justCancelledOrderList: [],
    };
  },
  [constants.DISMISS_JUST_CANCELLED_ORDER_SUCCESS]: (state, action) => {
    return {
      ...state,
    };
  },
  [constants.DISMISS_JUST_CANCELLED_ORDER_FAIL]: (state, action) => ({
    ...state,
  }),
  [constants.JUST_CANCELLED_ORDER_DETAILS]: (state, action) => {
    return {
      ...state,
      justCancelledSelectedOrderDetails: action.order,
    };
  },
  [constants.GET_INCOMING_ORDERS_SUCCESS]: (state, action) => {
    return {
      ...state,
      incomingOrders: action.data.data,
    };
  },
  [constants.GET_INCOMING_ORDERS_FAIL]: (state, action) => {
    return {
      ...state,
      incomingOrders: [],
    };
  },
  [constants.GET_STORE_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      storeDetails: action.data.store_data[0],
    };
  },
  [constants.GET_STORE_DETAILS_FAIL]: (state, action) => {
    return {
      ...state,
      storeDetails: {},
    };
  },
  [constants.GET_DEVICE_TOKEN_SUCCESS]: (state, action) => {
    return {
      ...state,
      deviceTokenDetails: action.data,
    };
  },
  [constants.GET_DEVICE_TOKEN_FAIL]: (state, action) => {
    return {
      ...state,
      deviceTokenDetails: [],
    };
  },
  [constants.GET_ORDER_DETAILS_BY_ID_SUCCESS]: (state, action) => ({
    ...state,
    orderHistoryList: action.data,
  }),
  [constants.GET_ORDER_DETAILS_BY_ID_FAIL]: (state, action) => ({
    ...state,
    orderHistoryList: [],
  }),
  [constants.SET_FILTER_ON_ORDER_HISTORY]: (state, action) => {
    return {
      ...state,
      orderHistoryFilter: action.data,
    };
  },

  [constants.GET_PARTNERS_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      partnerList: action.data,
    };
  },
  [constants.GET_PARTNERS_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      partnerList: [],
    };
  },
  [constants.GET_INCOMING_ORDERS_NOTIFICATION]: (state, action) => {
    return {
      ...state,
      incomingOrderNotifications: action.data,
    };
  },
  [constants.GET_JUST_CANCELLED_ORDER_NOTIFICATION]: (state, action) => {
    return {
      ...state,
      cancelledOrderNotifications: action.data,
    };
  },
  [constants.GET_NOTIFICATION_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      notificationList: action.data,
    };
  },
  [constants.GET_NOTIFICATION_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      notificationList: [],
    };
  },
  [constants.UPDATE_ORDER_STATUS_SUCCESS]: (state, action) => {
    return {
      ...state,
      updateOrderStatus: action.data,
    };
  },
  [constants.UPDATE_ORDER_STATUS_FAIL]: (state, action) => {
    return {
      ...state,
      updateOrderStatus: [],
    };
  },
  [constants.UPDATE_NOTIFICATION_STATUS_SUCCESS]: (state, action) => {
    return {
      ...state,
      updateNotificationStatus: action.data,
    };
  },
  [constants.UPDATE_NOTIFICATION_STATUS_FAIL]: (state, action) => {
    return {
      ...state,
      updateNotificationStatus: [],
    };
  },
  [constants.GET_SINGLE_ITEM_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      getSingleItemDetails: action.data,
    };
  },
  [constants.GET_SINGLE_ITEM_DETAILS_FAIL]: (state, action) => {
    return {
      ...state,
      getSingleItemDetails: [],
    };
  },
  [constants.GET_REPORT_COUNT_SUCCESS]: (state, action) => {
    return {
      ...state,
      orderReportListCount: action.data,
    };
  },
  [constants.GET_REPORT_COUNT_FAIL]: (state, action) => {
    return {
      ...state,
      orderReportListCount: [],
    };
  },
  [constants.UPDATE_EDIT_ORDER_SUCCESS]: (state, action) => {
    return {
      ...state,
      updatedEditOrder: action.data,
    };
  },
  [constants.UPDATE_EDIT_ORDER_FAIL]: (state, action) => {
    return {
      ...state,
      updatedEditOrder: [],
    };
  },
  [constants.SHOW_EDITED_ORDER]: (state, action) => {
    return {
      ...state,
      showEditedOrder: action.editedOrder,
    };
  },
  [constants.STORE_PARTIALLY_ACCEPTED_ITEM]: (state, action) => {
    return {
      ...state,
      storePartiallyAcceptedItems: action.item,
    };
  },
  [constants.CLEAR_ALL_SELECTIONS]: (state, action) => {
    return initialState;
  },

  [constants.SET_SHOW_EDIT_ORDER_SUCCESS]: (state, action) => {
    return {
      ...state,
      showEditOrder: true,
    };
  },
  [constants.SET_SHOW_EDIT_ORDER_FAIL]: (state, action) => {
    return {
      ...state,
      showEditOrder: false,
    };
  },
  [constants.STORE_PRINT_OPTION]: (state, action) => {
    return {
      ...state,
      showPrintOption: action.printOption,
    };
  },
  [constants.SET_SHOW_TOP_MSG]: (state, action) => {
    return {
      ...state,
      showTopMsg: action.data,
    };
  },
  [constants.GET_USER_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      userDetails: action.data,
    };
  },
  [constants.GET_USER_DETAILS_FAIL]: (state, action) => {
    return {
      ...state,
      userDetails: [],
    };
  },
  [constants.CANCEL_INCOMING_ORDER_SUCCESS]: (state, action) => {
    return {
      ...state,
      cancelIncomingOrder: action.data,
    };
  },
  [constants.CANCEL_INCOMING_ORDER_FAIL]: (state, action) => {
    return {
      ...state,
      cancelIncomingOrder: [],
    };
  },
  [constants.SET_ALL_NOTIFICATION_COUNT]: (state, action) => {
    return {
      ...state,
      totalNotificationCount: action.data,
    };
  },
  [constants.SET_RECENT_NOTIFICATIONS_LIST]: (state, action) => {
    return {
      ...state,
      recentNotificationsList: action.data,
    };
  },
  [constants.SET_ALL_NOTIFICATIONS_LIST]: (state, action) => {
    return {
      ...state,
      allNotificationsList: action.data,
    };
  },
  [constants.SET_ALL_RAW_NOTIFICATIONS_LIST]: (state, action) => {
    return {
      ...state,
      allRawNotificationsList: action.data,
    };
  },
  [constants.LOGOUT_FROM_APPLICATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      logoutApp: action.data,
    };
  },
  [constants.LOGOUT_FROM_APPLICATION_FAIL]: (state, action) => {
    return {
      ...state,
      logoutApp: [],
    };
  },
  [constants.SET_CURRENT_PATH]: (state, action) => {
    return {
      ...state,
      currentPath: action.path,
    };
  },
  [constants.UPDATE_STORE_STATUS]: (state, action) => {
    const storeDetails = { ...state.storeDetails };
    storeDetails.store_status = action.storeStatus;
    return {
      ...state,
      storeDetails: storeDetails,
    };
  },
  [constants.GET_PROFILE_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      profileDetails: action.data,
    };
  },
  [constants.GET_PROFILE_DETAILS_FAIL]: (state, action) => {
    return {
      ...state,
      profileDetails: [],
    };
  },
  [constants.GET_BACKUP_INCOMING_ORDERS_SUCCESS]: (state, action) => {
    return {
      ...state,
      backupIncomingOrder: action.data.data,
    };
  },
  [constants.GET_BACKUP_INCOMING_ORDERS_FAIL]: (state, action) => {
    return {
      ...state,
      backupIncomingOrder: [],
    };
  },
  [constants.STORE_DEVICE_TOKEN]: (state, action) => {
    return {
      ...state,
      storeDeviceToken: action.token,
    };
  },
  [constants.SAVE_HEADER_MSG_ORDER_ID]: (state, action) => {
    return {
      ...state,
      currentHeaderMsgOrderId: action.orderId,
    };
  },
  [constants.SET_SHOW_MENU_POPUP]: (state, action) => {
    return {
      ...state,
      showMenuPopUp: action.data,
    };
  },
  [constants.GET_MENU_SEARCH_RESULTS]: (state, action) => {
    return {
      ...state,
      menuSearchResults: action.data,
    };
  },
  [constants.GET_MENU_SEARCH_RESULTS_FAIL]: (state, action) => {
    return {
      ...state,
      menuSearchResults: [],
    };
  },
  [constants.SET_SHOW_MENU_POPUP_MODIFIER]: (state, action) => {
    return {
      ...state,
      showMenuPopUpModifier: action.data,
    };
  },
  [constants.TRIGER_EDIT_ORDERS]: (state, action) => {
    return {
      ...state,
      trigerEditOrder: action.trigerEditOrders,
    };
  },
  [constants.SET_TOGGLE_ORDER_SUMMARY]: (state, action) => {
    return {
      ...state,
      shouldShowSummary: action.data,
    };
  },
  [constants.COMPLETE_ORDER_SUCCESS]: (state, action) => {
    return {
      ...state,
      completeOrder: action.data,
    };
  },
  [constants.COMPLETE_ORDER_FAIL]: (state, action) => {
    return {
      ...state,
      completeOrder: [],
    };
  },
  [constants.CANCEL_ORDER_SUCCESS]: (state, action) => {
    return {
      ...state,
      cancelOrder: action.data,
    };
  },
  [constants.CANCEL_ORDER_FAIL]: (state, action) => {
    return {
      ...state,
      cancelOrder: [],
    };
  },
  [constants.SET_SHOULD_SHOW_EDIT_ORDER_VIEW_IN_ORDER_DETAILS_PAGE]: (
    state,
    action
  ) => {
    return {
      ...state,
      shouldShowEditOrderViewInOrderDetailsPage: action.data,
    };
  },
  [constants.SET_IS_FROM_SALE_INGESTION_TAP_TO_VIEW]: (state, action) => {
    return {
      ...state,
      isFromSaleInjectionTapToView: action.data,
    };
  },
};

export function orderManagementRelated(state = initialState, action = {}) {
  const actions = orderManagementActionFunctions;

  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action);
  } else {
    return state;
  }
}
