export const constants = {
  GET_ORDER_LIST_SUCCESS: 'GET_ORDER_LIST_SUCCESS',
  GET_ORDER_LIST_FAIL: 'GET_ORDER_LIST_FAIL',
  STORE_ORDER_DETAILS: 'STORE_ORDER_DETAILS',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  GET_ORDER_HISTORY_LIST_SUCCESS: 'GET_ORDER_HISTORY_LIST_SUCCESS',
  GET_ORDER_HISTORY_LIST_FAIL: 'GET_ORDER_HISTORY_LIST_FAIL',
  STORE_ORDER_HISTOY_DETAILS: 'STORE_ORDER_HISTOY_DETAILS',
  GET_JUST_CANCELLED_ORDER_LIST_SUCCESS:
    'GET_JUST_CANCELLED_ORDER_LIST_SUCCESS',
  GET_JUST_CANCELLED_ORDER_LIST_FAIL: 'GET_JUST_CANCELLED_ORDER_LIST_FAIL',
  DISMISS_JUST_CANCELLED_ORDER_SUCCESS: 'DISMISS_JUST_CANCELLED_ORDER_SUCCESS',
  DISMISS_LIVE_ORDER_FAIL: 'DISMISS_LIVE_ORDER_FAIL',
  DISMISS_LIVE_ORDER_SUCCESS: 'DISMISS_LIVE_ORDER_SUCCESS',
  JUST_CANCELLED_ORDER_DETAILS: 'JUST_CANCELLED_ORDER_DETAILS',
  GET_INCOMING_ORDERS_SUCCESS: 'GET_INCOMING_ORDERS_SUCCESS',
  GET_INCOMING_ORDERS_FAIL: 'GET_INCOMING_ORDERS_FAIL',
  GET_STORE_DETAILS_SUCCESS: 'GET_STORE_DETAILS_SUCCESS',
  GET_STORE_DETAILS_FAIL: 'GET_STORE_DETAILS_FAIL',
  GET_DEVICE_TOKEN_SUCCESS: 'GET_DEVICE_TOKEN_SUCCESS',
  GET_DEVICE_TOKEN_FAIL: 'GET_DEVICE_TOKEN_FAIL',
  SET_STORE_STATUS_SUCCESS: 'SET_STORE_STATUS_SUCCESS',
  SET_STORE_STATUS_FAIL: 'SET_STORE_STATUS_FAIL',
  GET_PARTNERS_LIST_SUCCESS: 'GET_PARTNERS_LIST_SUCCESS',
  GET_PARTNERS_LIST_FAIL: 'GET_PARTNERS_LIST_FAIL',
  HIDE_ERROR_PAGE: 'HIDE_ERROR_PAGE',
  SHOW_ERROR_PAGE: 'SHOW_ERROR_PAGE',
  SET_FILTER_ON_ORDER_HISTORY: 'SET_FILTER_ON_ORDER_HISTORY',
  FILTER_ORDER_HISTORY: 'FILTER_ORDER_HISTORY',
  UPDATE_ORDER_HISTORY_LIST: 'UPDATE_ORDER_HISTORY_LIST',
  GET_ORDER_DETAILS_BY_ID_SUCCESS: 'GET_ORDER_DETAILS_BY_ID_SUCCESS',
  GET_ORDER_DETAILS_BY_ID_FAIL: 'GET_ORDER_DETAILS_BY_ID_FAIL',
  GET_INCOMING_ORDERS_NOTIFICATION: 'GET_INCOMING_ORDERS_NOTIFICATION',
  GET_JUST_CANCELLED_ORDER_NOTIFICATION:
    'GET_JUST_CANCELLED_ORDER_NOTIFICATION',
  CLEAR_ALL_SELECTIONS: 'CLEAR_ALL_SELECTIONS',
  GET_NOTIFICATION_LIST_SUCCESS: 'GET_NOTIFICATION_LIST_SUCCESS',
  GET_NOTIFICATION_LIST_FAIL: 'GET_NOTIFICATION_LIST_FAIL',
  UPDATE_ORDER_STATUS_SUCCESS: 'UPDATE_ORDER_STATUS_SUCCESS',
  UPDATE_ORDER_STATUS_FAIL: 'UPDATE_ORDER_STATUS_FAIL',
  UPDATE_NOTIFICATION_STATUS_FAIL: 'UPDATE_NOTIFICATION_STATUS_FAIL',
  UPDATE_NOTIFICATION_STATUS_SUCCESS: 'UPDATE_NOTIFICATION_STATUS_SUCCESS',
  GET_NOTIFIED_ITEM_DETAILS_SUCCESS: 'GET_NOTIFIED_ITEM_DETAILS_SUCCESS',
  GET_NOTIFIED_ITEM_DETAILS_FAIL: 'GET_NOTIFIED_ITEM_DETAILS_FAIL',
  GET_SINGLE_ITEM_DETAILS_SUCCESS: 'GET_SINGLE_ITEM_DETAILS_SUCCESS',
  GET_SINGLE_ITEM_DETAILS_FAIL: 'GET_SINGLE_ITEM_DETAILS_FAIL',
  GET_REPORT_COUNT_SUCCESS: 'GET_REPORT_COUNT_SUCCESS',
  GET_REPORT_COUNT_FAIL: 'GET_REPORT_COUNT_FAIL',
  STORE_PRINT_OPTION: 'STORE_PRINT_OPTION',
  UPDATE_EDIT_ORDER_SUCCESS: 'UPDATE_EDIT_ORDER_SUCCESS',
  UPDATE_EDIT_ORDER_FAIL: 'UPDATE_EDIT_ORDER_FAIL',
  SHOW_EDITED_ORDER: 'SHOW_EDITED_ORDER',
  STORE_PARTIALLY_ACCEPTED_ITEM: 'STORE_PARTIALLY_ACCEPTED_ITEM',
  SET_SHOW_EDIT_ORDER_SUCCESS: 'SET_SHOW_EDIT_ORDER_SUCCESS',
  SET_SHOW_EDIT_ORDER_FAIL: 'SET_SHOW_EDIT_ORDER_FAIL',
  SET_SHOW_TOP_MSG: 'SET_SHOW_TOP_MSG',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAIL: 'GET_USER_DETAILS_FAIL',
  SAVE_ACCESS_TOKEN: 'SAVE_ACCESS_TOKEN',
  CANCEL_INCOMING_ORDER_SUCCESS: 'CANCEL_INCOMING_ORDER_SUCCESS',
  CANCEL_INCOMING_ORDER_FAIL: 'CANCEL_INCOMING_ORDER_FAIL',
  API_ERROR_CODE: 'Error Code',
  CLEAR_ALL_AUTH_INFO: 'CLEAR_ALL_AUTH_INFO',
  SET_ALL_NOTIFICATION_COUNT: 'SET_ALL_NOTIFICATION_COUNT',
  SET_RECENT_NOTIFICATIONS_LIST: 'SET_RECENT_NOTIFICATIONS_LIST',
  SET_ALL_NOTIFICATIONS_LIST: 'SET_ALL_NOTIFICATIONS_LIST',
  SET_ALL_RAW_NOTIFICATIONS_LIST: 'SET_ALL_RAW_NOTIFICATIONS_LIST',
  LOGOUT_FROM_APPLICATION_SUCCESS: 'LOGOUT_FROM_APPLICATION_SUCCESS',
  LOGOUT_FROM_APPLICATION_FAIL: 'LOGOUT_FROM_APPLICATION_FAIL',
  SAVE_AUTH_INFO: 'SAVE_AUTH_INFO',
  CLEAR_AUTH_INFO: 'CLEAR_AUTH_INFO',
  SAVE_REDIRECT_PATH: 'SAVE_REDIRECT_PATH',
  CLEAR_REDIRECT_PATH: 'CLEAR_REDIRECT_PATH',
  SET_CURRENT_PATH: 'SET_CURRENT_PATH',
  UPDATE_STORE_STATUS: 'UPDATE_STORE_STATUS',
  GET_PROFILE_DETAILS_SUCCESS: 'GET_PROFILE_DETAILS_SUCCESS',
  GET_PROFILE_DETAILS_FAIL: 'GET_PROFILE_DETAILS_FAIL',
  GET_BACKUP_INCOMING_ORDERS_SUCCESS: 'GET_BACKUP_INCOMING_ORDERS_SUCCESS',
  GET_BACKUP_INCOMING_ORDERS_FAIL: 'GET_BACKUP_INCOMING_ORDERS_FAIL',
  CALL_REFRESH_TOKEN: 'CALL_REFRESH_TOKEN',
  REMOVE_DEVICE_TOKEN_SUCCESS: 'REMOVE_DEVICE_TOKEN_SUCCESS',
  REMOVE_DEVICE_TOKEN_FAIL: 'REMOVE_DEVICE_TOKEN_FAIL',
  STORE_DEVICE_TOKEN: 'STORE_DEVICE_TOKEN',
  SAVE_HEADER_MSG_ORDER_ID: 'SAVE_HEADER_MSG_ORDER_ID',
  SET_SHOW_MENU_POPUP: 'SET_SHOW_MENU_POPUP',
  GET_MENU_SEARCH_RESULTS: 'GET_MENU_SEARCH_RESULTS',
  GET_MENU_SEARCH_RESULTS_FAIL: 'GET_MENU_SEARCH_RESULTS_FAIL',
  SET_SHOW_MENU_POPUP_MODIFIER: 'SET_SHOW_MENU_POPUP_MODIFIER',
  TRIGER_EDIT_ORDERS: 'TRIGER_EDIT_ORDERS',
  GET_CATEGORIES_LIST_SUCCESS: 'GET_CATEGORIES_LIST_SUCCESS',
  GET_CATEGORIES_LIST_FAIL: 'GET_CATEGORIES_LIST_FAIL',
  SET_TOGGLE_ORDER_SUMMARY: 'SET_TOGGLE_ORDER_SUMMARY',
  GET_PRODUCT_LIST_SUCCESS: 'GET_PRODUCT_LIST_SUCCESS',
  GET_PRODUCT_LIST_FAIL: 'GET_PRODUCT_LIST_FAIL',
  GET_PRODUCT_NAME_SUCCESS: 'GET_PRODUCT_NAME_SUCCESS',
  GET_PRODUCT_NAME_FAIL: 'GET_PRODUCT_NAME_FAIL',
  UPDATE_PRODUCT_STATUS_SUCCESS: 'UPDATE_PRODUCT_STATUS_SUCCESS',
  UPDATE_PRODUCT_STATUS_FAIL: 'UPDATE_PRODUCT_STATUS_FAIL',
  STORE_SEARCH_QUERY: 'STORE_SEARCH_QUERY',
  SET_CURRENT_ACTIVE_CATEGORY: 'SET_CURRENT_ACTIVE_CATEGORY',
  SET_UNAVAILABLE_COUNT: 'SET_UNAVAILABLE_COUNT',
  GET_TOTAL_UNAVAILABLE_COUNT_SUCCESS: 'GET_TOTAL_UNAVAILABLE_COUNT_SUCCESS',
  GET_TOTAL_UNAVAILABLE_COUNT_FAIL: 'GET_TOTAL_UNAVAILABLE_COUNT_FAIL',
  GET_TOTAL_UNAVAILABLE_ITEM_LIST_SUCCESS:
    'GET_TOTAL_UNAVAILABLE_ITEM_LIST_SUCCESS',
  GET_TOTAL_UNAVAILABLE_ITEM_LIST_FAIL: 'GET_TOTAL_UNAVAILABLE_ITEM_LIST_FAIL',
  SET_MENU_MODIFIERS: 'SET_MENU_MODIFIERS',
  SET_MENU_SUB_MODIFIERS: 'SET_MENU_SUB_MODIFIERS',
  SET_CURRENT_ACTIVE_PRODUCT: 'SET_CURRENT_ACTIVE_PRODUCT',
  SET_CURRENT_ACTIVE_MODIFIER: 'SET_CURRENT_ACTIVE_MODIFIER',
  SET_MODIFIER_PRODUCT_KEY: 'SET_MODIFIER_PRODUCT_KEY',
  SET_MODIFIER_LEVEL2_PRODUCT_KEY: 'SET_MODIFIER_LEVEL2_PRODUCT_KEY',
  SET_OPTION_NAME: 'SET_OPTION_NAME',
  SET_OPTION_LEVEL2_NAME: 'SET_OPTION_LEVEL2_NAME',
  CLEAR_ALL_MENU_SELECTIONS: 'CLEAR_ALL_MENU_SELECTIONS',
  COMPLETE_ORDER_SUCCESS: 'COMPLETE_ORDER_SUCCESS',
  COMPLETE_ORDER_FAIL: 'COMPLETE_ORDER_FAIL',
  CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAIL: 'CANCEL_ORDER_FAIL',
  SET_ALL_PRODUCTS_LIST: 'SET_ALL_PRODUCT_LIST',
  SET_ALL_MODIFIERS_LIST: 'SET_ALL_MODIFIERS_LIST',
  SET_IS_APP_INITIALIZE_AFTER_LOGIN: 'SET_IS_APP_INITIALIZE_AFTER_LOGIN',
  SET_ALL_PRODUCTS_LIST_FROM_SEARCH_QUERY:
    'SET_ALL_PRODUCTS_LIST_FROM_SEARCH_QUERY',
  SET_ALL_MODIFIERS_LIST_FROM_SEARCH_QUERY:
    'SET_ALL_MODIFIERS_LIST_FROM_SEARCH_QUERY',
  SET_SHOULD_SHOW_EDIT_ORDER_VIEW_IN_ORDER_DETAILS_PAGE:
    'SET_SHOULD_SHOW_EDIT_ORDER_VIEW_IN_ORDER_DETAILS_PAGE',
  MARK_SALE_INGESTION_SUCCESS: 'MARK_SALE_INGESTION_SUCCESS',
  MARK_SALE_INGESTION_FAIL: 'MARK_SALE_INGESTION_FAIL',
  SET_IS_FROM_SALE_INGESTION_TAP_TO_VIEW:
    'SET_IS_FROM_SALE_INGESTION_TAP_TO_VIEW',
};
