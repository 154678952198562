const url = process.env.REACT_APP_URL;
const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const loginUrl = `${process.env.REACT_APP_LOGIN_URL}${redirectUrl}`;
const logoutUrl = `${process.env.REACT_APP_LOGOUT_URL}${redirectUrl}`;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
const authUrl = process.env.REACT_APP_AUTH_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const projectId = process.env.REACT_APP_PROJECT_ID;
const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_APP_ID;
const measurementId = process.env.REACT_APP_MEASUREMENT_ID;
const launchDarklyClientId = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;

export const apiUrl = {
  url,
  loginUrl,
  logoutUrl,
  authUrl,
  clientId,
  redirectUrl,
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
  clientSecret,
};
