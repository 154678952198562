import { apiUrl } from '../url/apiUrl';
import { handleResponse, isAllPartnerSelected } from '../helper/common';
import { sort } from 'fast-sort';
import { sortingConstants } from '../constant/sortingConstant';
import { store } from '../helper/store';

export const orderManagementServices = {
  getOrderList,
  getJustCancelledOrderList,
  dismissLiveOrders,
  getOrderHistoryList,
  getIncomingOrders,
  getStoreDetails,
  addDeviceToken,
  updateStoreStatus,
  getPartnersList,
  filterOrderHistory,
  sortOrderHistoryList,
  searchForOrder,
  getNotificationsList,
  updateOrderStatus,
  updateNotificationStatus,
  getSingleItemDetails,
  getOrderReportCount,
  updateEditedOrders,
  getLoginUserDetails,
  cancelIcomingOrder,
  getAllNotificationsList,
  logoutFromApplication,
  getMyProfileDetails,
  removeDeviceToken,
  getSearchQuery,
  completeOrder,
  cancelOrder,
  updateAcceptedOrder,
  cancelAcceptedOrder,
  markSalesIngestion,
  postClickTouchEvents,
  notificationAcknowledgement,
};

const contentType = 'application/json';

const ifAllPartenersSelected = (filterOn, queryString) => {
  if (!isAllPartnerSelected(filterOn)) {
    queryString += 'partner_name=';
    if (filterOn.includes('uberEats')) {
      queryString += 'Uber Eats, ';
    }
    if (filterOn.includes('menuLog')) {
      queryString += 'Menulog, ';
    }
    if (filterOn.includes('deliveroo')) {
      queryString += 'Deliveroo';
    }
    queryString += '&';
  }
  return queryString;
};

const ifCancelledStatus = (filterOn) => {
  let queryString = '';

  if (
    filterOn.includes('cancelledDriver') ||
    filterOn.includes('cancelledAggregator') ||
    filterOn.includes('cancelledCustomer')
  ) {
    if (filterOn.includes('cancelledDriver')) {
      queryString += 'Cancelled by Driver, ';
    }
    if (filterOn.includes('cancelledAggregator')) {
      queryString += 'Cancelled';
    }
    if (filterOn.includes('cancelledCustomer')) {
      queryString += 'Cancelled by Customer, ';
    }
  }
  return queryString;
};
const ifOrderStatus = (filterOn, queryString) => {
  if (
    filterOn.includes('completed') ||
    filterOn.includes('cancelledDriver') ||
    filterOn.includes('cancelledAggregator') ||
    filterOn.includes('cancelledCustomer')
  ) {
    queryString += 'partner_status=';
    if (filterOn.includes('completed')) {
      queryString += 'Completed, ';
    }
    queryString += ifCancelledStatus(filterOn);
    queryString += '&';
  }
  return queryString;
};

function postClickTouchEvents(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(data),
  };
  const url = `${apiUrl.url}/update_order_dismiss`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getOrderList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/active_orders`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getOrderHistoryList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/order_history?day=last week`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getJustCancelledOrderList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };

  const url = `${apiUrl.url}/just_cancelled_orders`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function dismissLiveOrders(orderId) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify({
      id: orderId,
      dismiss: 'true',
    }),
  };
  const url = `${apiUrl.url}/update_order_dismiss`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getIncomingOrders() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/incoming_orders`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getStoreDetails() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/my_store_details`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addDeviceToken(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/add_device_token`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function updateStoreStatus(storeStatus, reason, storeId) {
  const dateNow = new Date();
  dateNow.setUTCHours(23, 59, 59, 0);
  const dateToISO = dateNow.toISOString();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify({
      store_status: storeStatus,
      store_pause_reason: reason,
      paused_until: dateToISO,
    }),
  };
  const url = `${apiUrl.url}/update_my_store`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function setFilterQueries(filterOn) {
  let queryString = '';
  queryString += ifAllPartenersSelected(filterOn, queryString);
  if (filterOn.includes('sale_ingestion')) {
    queryString += 'sale_ingestion=Failed&';
  }
  queryString += ifOrderStatus(filterOn, queryString);
  return queryString;
}

function formatDate(date) {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = date.getFullYear();
  // This section is only to handle firefox browser issue which is adding "-" at beginning of year if we use getFullYear function
  yyyy = yyyy + '';
  if (yyyy[0] === '-') {
    yyyy = yyyy.substring(1);
  }
  return `${dd}/${mm}/${yyyy}`;
}

function filterOrderHistory(filterOn = []) {
  let queryString = setFilterQueries(filterOn);
  // To include last seven days filter by default
  if (filterOn.includes('today')) {
    queryString += 'day=today&';
  }
  for (let index = 0; index < filterOn.length; index++) {
    if (filterOn[index].custom) {
      const tmp = filterOn[index].custom.split(',');
      var today = new Date();
      const currentYear = today.getFullYear();
      const fromDate = formatDate(new Date(`${tmp[0]}-${currentYear}`));
      const toDate = formatDate(new Date(`${tmp[1]}-${currentYear}`));
      queryString += `from_date=${fromDate}&to_date=${toDate}`;
    }
  }
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/order_history?${queryString}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getPartnersList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/partners_list`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function sortOrderHistoryList(orderHistoryList, sortOn, sortBy) {
  if (sortBy === sortingConstants.ASCENDING) {
    if (sortOn === 'total_items') {
      return sort(orderHistoryList).asc((u) => u?.count?.total_items);
    } else if (sortOn === 'created_on') {
      return sort(orderHistoryList).asc(
        (u) => new Date(`${u.date} ${' '} ${u.time}`)
      );
    }
    return sort(orderHistoryList).asc((u) => u[sortOn]);
  } else {
    if (sortOn === 'total_items') {
      return sort(orderHistoryList).desc((u) => u?.count?.total_items);
    } else if (sortOn === 'created_on') {
      return sort(orderHistoryList).desc(
        (u) => new Date(`${u.date} ${' '} ${u.time}`)
      );
    }
    return sort(orderHistoryList).desc((u) => u[sortOn]);
  }
}

function searchForOrder(orderId, filters) {
  let queryString = '';
  if (Object.keys(filters).length !== 0) {
    queryString = setFilterQueries(filters);
    if (filters.includes('today')) {
      queryString += 'day=today&';
    }
    for (let index = 0; index < filters.length; index++) {
      if (filters[index].custom) {
        const tmp = filters[index].custom.split(',');
        var today = new Date();
        const currentYear = today.getFullYear();
        const fromDate = formatDate(new Date(`${tmp[0]}-${currentYear}`));
        const toDate = formatDate(new Date(`${tmp[1]}-${currentYear}`));
        queryString += `from_date=${fromDate}&to_date=${toDate}&`;
      }
    }
  }
  if (
    orderId === '' &&
    (!queryString.includes('day=') || !queryString.includes('from_date='))
  ) {
    queryString += 'day=last week';
  }
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/order_history?search=${orderId}&${queryString}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getNotificationsList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/notifications_list`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function updateOrderStatus(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/update_order_status`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function updateNotificationStatus(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/update_notification_status`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getSingleItemDetails(queryString) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/order_details?${queryString}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getOrderReportCount() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/tablet_report_count`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function updateEditedOrders(payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/update_order`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getLoginUserDetails(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/user_login`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function cancelIcomingOrder(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/update_order`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

async function getAllNotificationsList() {
  const allNotificationList = await getNotificationsList();
  if (allNotificationList.data) {
    let recentNotifications = getRecentNotificationList(
      allNotificationList.data
    );
    recentNotifications = sort(recentNotifications).desc(
      (u) => u['updated_on']
    );
    let allNotifications = getAllNotification(allNotificationList.data);
    allNotifications = sort(allNotifications).desc((u) => u['updated_on']);
    const allRawNotificationsList = sort(allNotificationList.data).desc(
      (u) => u['updated_on']
    );
    const totalNotificationCount =
      recentNotifications.length + allNotifications.length;
    return [
      recentNotifications,
      allNotifications,
      totalNotificationCount,
      allRawNotificationsList,
    ];
  } else {
    return [];
  }

  function isLessThan10Mins(date) {
    if (Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60) < 10) {
      return true;
    }
    return false;
  }
  function btw10to40Mins(date) {
    if (
      Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60) >= 10 &&
      Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60) <= 40
    ) {
      return true;
    }
    return false;
  }
  function getRecentNotificationList(currentNotificationList) {
    return currentNotificationList.filter((notification) =>
      isLessThan10Mins(notification.updated_on)
    );
  }
  function getAllNotification(currentNotificationList) {
    return currentNotificationList.filter((notification) =>
      btw10to40Mins(notification.updated_on)
    );
  }
}
function logoutFromApplication() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/logout`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getMyProfileDetails() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/my_profile`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function removeDeviceToken(payload) {
  const data = {
    device_token: payload,
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(data),
  };
  const url = `${apiUrl.url}/remove_device_token`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getSearchQuery(payload) {
  const data = {
    searchQuery: payload,
  };
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(data),
  };
  const url = `${apiUrl.url}/search_menu`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function completeOrder(orderList) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify({
      orders: orderList,
    }),
  };
  const url = `${apiUrl.url}/mark_completed_order`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function cancelOrder(orderList) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify({
      orders: orderList,
    }),
  };
  const url = `${apiUrl.url}/mark_cancelled_order`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function updateAcceptedOrder(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/update_accepted_order`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function cancelAcceptedOrder(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/cancel_accepted_order`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function markSalesIngestion(orderId) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify({
      order_id: orderId,
      status: 'success',
    }),
  };
  const url = `${apiUrl.url}/mark_sales_ingestion`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function notificationAcknowledgement(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/notification_acknowledgement`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
